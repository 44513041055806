var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"variant":_vm.loading?'primary':'danger',"show":!_vm.items}},[_c('div',{staticClass:"alert-body py-3 px-2"},[_c('span',[(_vm.loading)?_c('strong',[_vm._v("Veriler yükleniyor. "),_c('b-spinner',{attrs:{"label":"Loading..."}})],1):_c('strong',[_vm._v("Veri bulunamadı.")])])])]),(_vm.items)?_c('b-card',[_c('div',{staticClass:"custom-search d-flex justify-content-between"},[_c('b-form-group',[_c('b-button',{attrs:{"variant":"outline-primary","to":{name:_vm.categoryId?'blog_content_add':'blog_content_add_empty', params: _vm.categoryId?{category:_vm.categoryId}:{}}}},[_vm._v("Ekle")])],1),_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Arama")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Arama","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.items,"search-options":{
                enabled: true,
                externalQuery: _vm.searchTerm },"pagination-options":{
                enabled: true,
                perPage:_vm.pageLength
              },"sort-options":{
                enabled: true,
                initialSortBy: {field: 'id', type: 'desc'}
            }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'action')?_c('div',[_c('b-button-group',[_c('b-button',{attrs:{"variant":"outline-primary","to":{name:'blog_content_edit', params:{id:props.row.id}}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.deleteItem(props.row.id)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)],1):(props.column.field === 'categoryId')?_c('div',[_vm._v(" "+_vm._s(_vm.categoryListComputed[props.row.categoryId])+" ")]):_c('div',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing 1 to ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['5','10','20', '50']},on:{"input":function (value){ return props.perPageChanged({currentPerPage:value}); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap "},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value){ return props.pageChanged({currentPage:value}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,false,2089523923)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }