<template>
    <div>
        <b-alert :variant="loading?'primary':'danger'" :show="!items">
            <div class="alert-body py-3 px-2">
                <span><strong v-if="loading">Veriler yükleniyor. <b-spinner label="Loading..." /></strong><strong v-else>Veri bulunamadı.</strong></span>
            </div>
        </b-alert>
        <b-card v-if="items">

            <div class="custom-search d-flex justify-content-between">
                <b-form-group>
                    <b-button variant="outline-primary" :to="{name:categoryId?'blog_content_add':'blog_content_add_empty', params: categoryId?{category:categoryId}:{}}">Ekle</b-button>
                </b-form-group>
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <label class="mr-1">Arama</label>
                        <b-form-input
                            v-model="searchTerm"
                            placeholder="Arama"
                            type="text"
                            class="d-inline-block"
                        />
                    </div>
                </b-form-group>
            </div>



            <!-- table -->
            <vue-good-table
                :columns="columns"
                :rows="items"
                :search-options="{
                    enabled: true,
                    externalQuery: searchTerm }"
                :pagination-options="{
                    enabled: true,
                    perPage:pageLength
                  }"
                :sort-options="{
                    enabled: true,
                    initialSortBy: {field: 'id', type: 'desc'}
                }">
                <template
                    slot="table-row"
                    slot-scope="props"
                >



                    <div v-if="props.column.field === 'action'">
                        <b-button-group>
                            <b-button variant="outline-primary" :to="{name:'blog_content_edit', params:{id:props.row.id}}">
                                <feather-icon icon="EditIcon" />
                            </b-button>
                            <b-button variant="outline-primary" @click="deleteItem(props.row.id)">
                                <feather-icon icon="TrashIcon" />
                            </b-button>
                        </b-button-group>
                    </div>

                    <div v-else-if="props.column.field === 'categoryId'">
                        {{ categoryListComputed[props.row.categoryId] }}
                    </div>

                    <!-- Column: Common -->
                    <div v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </div>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap">
                              Showing 1 to
                            </span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['5','10','20', '50']"
                                class="mx-1"
                                @input="(value)=>props.perPageChanged({currentPerPage:value})"
                            />
                            <span class="text-nowrap "> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination
                                :value="1"
                                :total-rows="props.total"
                                :per-page="pageLength"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="(value)=>props.pageChanged({currentPage:value})"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </b-card>
    </div>
</template>

<script>
import {
    BAlert,
    BSpinner,
    BCard,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BImg,
    BButtonGroup,
    BButton,
    BForm,
    BRow,
    BCol


} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import store from "@/store";

export default {
    name: 'list',
    components: {
        BAlert,
        BSpinner,
        BCard,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        VueGoodTable,
        BImg,
        BButtonGroup,
        BButton,
        BForm,
        BRow,
        BCol

    },
    data(){
        return {
            categoryId: false,
            loading: true,
            items: false,
            preChange : {},
            categoryList:[],
            pageLength: 20,
            dir: false,
            columns: [
                {
                    label: '#',
                    field: 'id',
                    sortable: true,
                    type:'number',
                    width: '80px',
                    align: 'center',
                },
                {
                    label: 'Kategori',
                    field: 'categoryId',
                    sortable: false,
                    width: '100px'
                },
                {
                    label: 'Başlık',
                    field: 'title'
                },
                {
                    label: 'İşlemler',
                    field: 'action',
                    sortable: false,
                    width: '160px'
                },
            ],
            searchTerm: ''
        }
    },
    created() {
        let t = this
        t.categoryId = t.$route.params.category?t.$route.params.category:false
        let cat = this.categoryId ? '/'+this.categoryId:''

        t.$http.get('/admin/blog/category/list')
            .then(res => {
                if(res.data.status){
                    t.categoryList = res.data.data
                }
            }).catch(error=>{
            console.log(error)
        }).then(f=>{
            t.$http.get('/admin/blog/content/list'+cat)
                .then(res => {
                    if(res.data.status){
                        t.items = res.data.data
                    }else if(res.data.message){
                        t.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Hata`,
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                                text: res.data.message,
                            },
                        })
                    }
                }).catch(error=>{
                console.log(error)
            }).then(()=>{
                t.loading = false
            })
        })
        this.insertData.categoryId = this.$route.params.category
        store.commit('app/SET_EDITABLE', 0)
    },
    computed:{
        categoryListComputed(){
            let items = []
            for(let i = 0; i<this.categoryList.length; i++){
                items[this.categoryList[i].id] = this.categoryList[i].title
            }
            return items
        }
    },
    watch:{
        $route(){
            this.categoryId = this.$route.params.category
            this.setItems()
        }
    },
    methods: {
        setItems(){
            let t = this
            t.loading = true
            let cat = this.categoryId ? '/'+this.categoryId:''

            t.$http.get('/admin/blog/content/list'+cat)
                .then(res => {
                    if(res.data.status){
                        t.items = res.data.data
                    }else if(res.data.message){
                        t.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Hata`,
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                                text: res.data.message,
                            },
                        })
                    }
                }).catch(error=>{
                console.log(error)
            }).then(()=>{
                t.loading = false
            })
        },
        deleteItem(itemId){
            let t = this

            t.$swal({
                title: 'Emin misiniz?',
                text: 'İçeriği sildiğinizde bağlantılı tüm verilerle beraber tamamen silinecektir. Bu içeriği silmek istediğinize emin misiniz?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Evet, Sil!',
                cancelButtonText: 'İptal',
                customClass: {
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-outline-info ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {

                    store.commit('app/SET_LOADING', true)
                    t.$http.delete('admin/blog/content/delete/'+itemId).then(function (e){
                        if(e.data.status){
                            t.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: 'Başarılı',
                                    text: e.data.message?e.data.message:'İçerik başarıyla silindi.',
                                    icon: 'CheckIcon',
                                    variant: 'success',
                                },
                            })
                            let itemIndex = t.items.findIndex(e=>{return e.id === itemId})
                            t.items.splice(itemIndex, 1)
                        }
                    }).catch(e=>{
                        console.log(e)
                    }).then(f=>{
                        store.commit('app/SET_LOADING', false)
                    });

                } else if (result.dismiss === 'cancel') {
                    t.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `İptal Edildi!`,
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: 'İçerik silme işlemi iptal edildi.',
                        },
                    })
                }
            })
        },
        getItemIndex(id){
            return this.items.findIndex(e=>{return e.id===id})
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
